import styles from './card.module.css'

const Card = ({image, text, name}) => {
    return(
        <div className={styles.container}>
            <img className={styles.image} src={image}/>
            <p className={styles.text}>"{text}"</p>
            <h3 className={styles.name}>{name}</h3>
        </div>
    )
}

export default Card