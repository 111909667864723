import styles from './header.module.css'

const Header = () => {
    return(
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img src="/favicon.ico"/>
            </div>
            <div className={styles.linksContainer}>
                <a href='#sobre'>Sobre</a>
                <a href='#serviços'>Serviços</a>
                <a href='#contato'>Contato</a>
            </div>
        </div>
    )
}

export default Header