import styles from './imageBg.module.css'
import { ImageWithMask } from './imageWithMask'

const ImageBG = ({source}) => {
    return(
        <div className={styles.container}>
            <ImageWithMask source={source}/>
        </div>
    )
}

export default ImageBG