import whats from '../imgs/whatsapp.png'
import styles from './fabWhats.module.css'

const FabWhats = () => {
    return(
        <a className='' target='_blank' href='https://whatsa.me/5561998583348/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
            <img className={styles.fab} src={whats} />
        </a>
        )
}

export default FabWhats