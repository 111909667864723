import Header from "../components/header"
import styles from './layout.module.css'
import ScreenContainer from '../components/screenContainer'
import MainTitle from "../components/mainTitle"
import MiddleText from "../components/middleText"
import CtaBtn from "../components/callToActionBtn"
import Panel2 from '../imgs/instagram.png'
import photo_01 from '../imgs/01.jpeg'
import photo_03 from '../imgs/03.jpeg'
import logo from '../imgs/instagram.png'
import IconText from "../components/iconText"
import TextContainer from "../components/textContainer"
import Card from "../components/card"
import Links from "../components/links"
import Faq from "../components/faq"
import Footer from "../components/footer"
import FabWhats from "../components/fabWhats"
import ImageBG from "../components/imageBg"
import { ImageWithMask } from "../components/imageWithMask"
const cards = [
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
]
const goToWpp = () => {
   window.location.href = 'https://wa.me/61983794954'
}
const DivasStudio = () => {
    return (
        <div className={styles.container}>
            <ScreenContainer id='main' direction={'col'}>
                <Header/>
                <FabWhats/>
                <ImageBG source={photo_01}/>
                <div className={styles.textContainer}>
                    <div>
                        <span className={styles.mini}>método</span>
                        <MainTitle white title='LASH PREMIUM'/>
                        <MiddleText text='domine todas as técnicas de extensão de cílios' />
                    </div>
                    <CtaBtn text='Entrar em contato' onclick={goToWpp}/>
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'row'}>
                <div id='sobre' className={styles.textContainer}>
                    <MainTitle title='Sobre a Lash Premium'/>
                    <MiddleText text={`Descubra o segredo para se tornar uma Lash Artist de sucesso com Ana Carolina, premiada duas vezes como Lash Artist destaque. Com mais de 4 anos de experiência e formação pela Academy Booble, ela oferece um curso abrangente, do básico ao avançado. Receba orientação personalizada, aprenda técnicas inovadoras e faça parte de uma comunidade de profissionais talentosas. Desperte seu potencial, aprimore suas habilidades e embarque em uma carreira lucrativa no mundo das extensões de cílios. Garanta sua vaga agora mesmo!`} />
                    <div className={styles.photoContainer}>
                        <img className={styles.image} src={photo_03}/>
                    </div>
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'row'}>
                <div className={styles.textContainer}>
                    <img src={Panel2}/>
                </div>
                <div className={styles.textContainer}>
                    <MainTitle title='Divas Studio'/>
                    <MiddleText text={`Chamada!!`} />
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'col'}>
                <TextContainer >
                    <MainTitle title='Vantagens'/>
                    <MiddleText text='explicação!!' />
                </TextContainer>
                <div className={styles.iconsContainer}>
                    <IconText label='!!!' icon='savings'/>
                    <IconText label='!!!' icon='health_and_safety'/>
                    <IconText label='!!!' icon='schedule'/>
                    <IconText label='!!!' icon='bolt'/>
                    <IconText label='!!!' icon='recycling'/>
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'col'}>
                <IconText label='' icon='sunny'/>
                <TextContainer color>
                    <MainTitle white title='Divas Studio'/>
                    <MiddleText text='chamada!!!' />
                    <CtaBtn white text='Quero fazer um orçamento' onclick={goToWpp}/>
                </TextContainer>
            </ScreenContainer>
            <ScreenContainer direction={'col'}>
                <MainTitle title='Depoimentos'/>
                <br/>
                <div className={styles.iconsContainer}>
                    {cards?.map((card) => (
                        <Card {...card}/>
                    ))}
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'row'}>
                <div className={styles.textContainer}>
                    <img className={styles.logo} src={logo}/>
                </div>
                <div id="serviços" className={styles.textContainer}>
                    <MainTitle title='Faça parte'/>
                    <MiddleText text='Entre em contato conosco hoje mesmo para descobrir como!!!' />
                    <MiddleText text={`Atendemos: ....`} />
                <Links/>
                </div>
            </ScreenContainer>
            <ScreenContainer id='faq' direction={'col'}>
                <MainTitle title='Perguntas Frequentes'/>
                <Faq/>
            </ScreenContainer>
            <Footer id='contato' isLandingPage/>
        </div>
    )
}

export default DivasStudio